import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import "../../components/layout.css"
import "../../components/layout-articles-home.css"
import BlogListings from "../../components/BlogListings"
// import '../css/index.css'; // add some style if you want!
export default function Index({ data }) {
    const { edges: posts } = data.allMarkdownRemark
    return (
        <div className="container">
        <hr />
        <pre>jpaek.com/blog</pre>
        <hr />
        <div className="blog-posts">
        <BlogListings posts={posts}/>
        </div>
        </div>
    )
}
export const pageQuery = graphql`
  query myBlogIndexQuery {
      allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
      ) {
          edges {
              node {
                  excerpt(pruneLength: 250)
                  id
                  fields {
                      collection
                  }
                  frontmatter {
                      title
                      date(formatString: "MMMM DD, YYYY")
                      path
                  }
              }
          }
      }
  }
`

