import React from 'react'
import { Link } from 'gatsby'
export default function BlogListings({posts}) {

    return (
        <div>
        {posts
            .filter(post => post.node.frontmatter.title.length > 0)
            .map(({ node }) => (
            <>
                <div className="blog-post-preview my-1 px-1 py-2 bg-slate-50 rounded-lg border-l-4 border-indigo-500" key={node.id}>
                    <h5><Link to={`/${node.fields.collection}${node.frontmatter.path}`}>{node.frontmatter.title}</Link></h5>
                    <p className="text-xs text-gray-500 my-0">{node.frontmatter.date}</p>
                    <p>{node.excerpt}</p>
                </div>
            </>

        ))}
        </div>
    )
}
